
import React, { useState, useEffect } from 'react';


function Header(props) {
    let add =props.isFixed === true ? " nav-fixed" : "";
    let clazz = "fixed-top" + add;

    let logoSrc = props.isFixed === true ? "assets/images/logo-black.png" : "assets/images/logo.png";

    return (
        <header id="site-header" className={clazz}>
            <div className="container">
                <nav className="navbar navbar-expand-lg stroke px-0 pt-lg-0">
                    <a className="navbar-brand" href="/">
                        <img alt="Main logo" id="main-logo" src={logoSrc} width="250px" />
                    </a>
                    {/*<!-- if logo is image enable this   
  <a className="navbar-brand" href="#index.html">
      <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
    </a> -->*/}
                    <button className="navbar-toggler  collapsed bg-gradient" type="button" data-toggle="collapse"
                        data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon fa icon-expand fa-bars"></span>
                        <span className="navbar-toggler-icon fa icon-close fa-times"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav mr-lg-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/team">Team</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/services">Services</a>
                            </li>

                            {/*

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#Blog" id="navbarDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Services <span className="fa fa-angle-down"></span>
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a className="dropdown-item" href="blog.html">Blog posts</a>
                                    <a className="dropdown-item" href="blog-single.html">Blog single post</a>
                                </div>
                            </li>
                             */}
                            
                            
                            
                            {/*
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#Pages" id="navbarDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Pages <span className="fa fa-angle-down"></span>
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a className="dropdown-item" href="services.html">Services</a>
                                    <a className="dropdown-item" href="/error">404 page</a>
                                    <a className="dropdown-item" href="email.html">Email Page</a>
                                    <a className="dropdown-item" href="elements.html">Elements</a>
                                    <a className="dropdown-item" href="landing-single.html">Landing page</a>
                                    <a className="dropdown-item" href="/contact">Contact us</a>
                                </div>
                            </li>
                            */}
                            <li className="nav-item">
                                <a className="nav-link" href="/contact">Contact</a>
                            </li>
                        </ul>

                        {/* 
                        <div className="search-right">
                            <div id="wrap">
                                <form action="" autocomplete="on">
                                    <input id="search" name="search" type="text" placeholder="Search here..." /><input id="search_submit"
                                        value="Rechercher" type="submit" />
                                </form>
                            </div>

                        </div>
                        */}
                        

                        <div className="top-quote mr-lg-3 mt-lg-0 mr-lg-4">
                            <a href="/contact" className="btn btn-style btn-white btn-primary">Request a Quote</a>
                        </div>
                    </div>

                    {/* Night Light */}
                    {/* 
                    <div className="mobile-position">
                        <nav className="navigation">
                            <div className="theme-switch-wrapper">
                                <label className="theme-switch" for="checkbox">
                                    <input type="checkbox" id="checkbox" />
                                    <div className="mode-container">
                                        <i className="gg-sun"></i>
                                        <i className="gg-moon"></i>
                                    </div>
                                </label>
                            </div>
                        </nav>
                    </div>
                    */}
                    

                </nav>
            </div>
        </header>
    );
}

export default Header;