import Header from '../Components/Header';
import Footer from '../Components/Footer';
import React, { useState, useEffect } from 'react';

function Home() {
    useEffect(() => {
        const $ = window.$;

        $('.owl-one').owlCarousel({
            loop: true,
            margin: 0,
            nav: false,
            responsiveClass: true,
            autoplay: true,
            autoplayTimeout: 9000,
            autoplaySpeed: 2000,
            autoplayHoverPause: false,
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 1
                },
                667: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }
        })
        $("#owl-demo1").owlCarousel({
            loop: true,
            margin: 20,
            nav: false,
            autoplay: true,
            responsiveClass: true,
            autoplaySpeed: 1000,
            autoplayTimeout: 5000,
            autoplayHoverPause: false,
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                736: {
                    items: 1,
                    nav: false
                },
                1000: {
                    items: 1,
                    nav: false,
                    loop: false
                }
            }
        })
        $(window).on("scroll", function () {
            var scroll = $(window).scrollTop();

            if (scroll >= 80) {
                $("#site-header").addClass("nav-fixed");
                $("#main-logo").attr("src", "assets/images/logo-black.png");
            } else {
                $("#site-header").removeClass("nav-fixed");
                $("#main-logo").attr("src", "assets/images/logo.png");
            }
        });

        //Main navigation Active Class Add Remove
        $(".navbar-toggler").on("click", function () {
            $("header").toggleClass("active");
        });
        $(document).on("ready", function () {
            if ($(window).width() > 991) {
                $("header").removeClass("active");
            }
            $(window).on("resize", function () {
                if ($(window).width() > 991) {
                    $("header").removeClass("active");
                }
            });
        });

        $('.owl-carousel').each(function() {
            //Find each set of dots in this carousel
          $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).attr('aria-label', index + 1);
          });
        });

        const items = window.document.querySelectorAll(".accordion button");

        function toggleAccordion() {
            const itemToggle = this.getAttribute('aria-expanded');

            for (var i = 0; i < items.length; i++) {
                items[i].setAttribute('aria-expanded', 'false');
            }

            if (itemToggle == 'false') {
                this.setAttribute('aria-expanded', 'true');
            }
        }

        items.forEach(item => item.addEventListener('click', toggleAccordion));

        window.onscroll = function () {
            scrollFunction()
        };

        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                document.getElementById("movetop").style.display = "block";
            } else {
                document.getElementById("movetop").style.display = "none";
            }
        }

    });

    return (
        <body>
            <Header />

            <section className="w3l-main-slider banner-slider" id="home">
                <div className="owl-one owl-carousel owl-theme">
                    <div className="item">
                        <div className="slider-info banner-view banner-top1">
                            <div className="container">
                                <div className="banner-info header-hero-19">
                                    <p className="text-tag">About Us</p>
                                    <h1 className="title-hero-19">Small Business and Home Office IT Services</h1>
                                    <div className="d-grid grid-col-3 mai-hero-19">
                                        <div className="grid-hero-19">
                                            <span className="fas fa-network-wired"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"> </p>
                                                <h5><a href="services">Networking</a></h5>
                                            </div>
                                        </div>
                                        <div className="grid-hero-19">
                                            <span className="fas fa-tv"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"></p>
                                                <h5><a href="services">Digital Signage</a></h5>
                                            </div>
                                        </div>
                                        <div className="grid-hero-19 third-gdw3">
                                            <span className="fas fa-cloud"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"> </p>
                                                <h5><a href="services">Cloud Services</a></h5>
                                            </div>
                                        </div>
                                        <div className="grid-hero-19">
                                            <span className="fas fa-laptop-house"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"> </p>
                                                <h5><a href="services">IT Support & Remote Management</a></h5>
                                            </div>
                                        </div>
                                        <div className="grid-hero-19">
                                            <span className="fas fa-cash-register"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"> </p>
                                                <h5><a href="services">Point of Sale</a></h5>
                                            </div>
                                        </div>
                                        <div className="grid-hero-19 third-gdw3">
                                            <span className="fas fa-ellipsis-h"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"> </p>
                                                <h5><a href="services">And More</a></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="slider-info banner-view banner-top2">
                            <div className="container">
                                <div className="banner-info header-hero-19">
                                    <p className="text-tag">Our Committment</p>
                                    <h1 className="title-hero-19 mb-4">Great Technology, Even Better Service</h1>
                                    <p className="hnypara">By utilizing industry-trusted IT products and the latest tools, we ensure that our support for your business is both efficient and up-to-date. Staying ahead of modern IT trends allows us to deliver seamless solutions with minimal disruption.</p>
                                    <div className="w3banner-buttons d-flex">
                                        <a href="#brands" className="btn btn-style btn-primary mt-sm-5 mt-4">View Product Lineup</a>
                                        {/*<a href="about.html" className="btn btn-style btn-outline-light mt-sm-5 mt-4 ml-3">View Warranty Details</a>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="slider-info banner-view banner-top3">
                            <div className="container">
                                <div className="banner-info header-hero-19">
                                    <p className="text-tag">Contact Us</p>
                                    <h1 className="title-hero-19" style={{ marginBottom: "0px" }}>Have a New Project or Need Technology Support?</h1>
                                    <div className="w3banner-buttons">
                                        <a href="/contact" className="btn btn-style btn-primary mt-sm-5 mt-4">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="w3l-3-grids py-5" id="grids-3">
                <div className="container py-md-5 py-2">

                    <div className="row">
                        <div className="col-lg-8 pr-lg-3 mb-3">
                            <div className="title-content text-left">
                                <h6 className="title-subhny mb-2">About Us</h6>
                                <h1 className="title-w3l">Your Local Technology Company.</h1>
                            </div>
                        </div>

                    </div>
                    <div className="mt-lg-0 mt-md-5 mt-4 mb-3 text-left">

                        <p className="pr-lg-5">Solace Integrations is a comprehensive technology services provider and integrator located in Westchester County, NY. As businesses increasingly rely on complex technology, managing and maintaining these systems can be overwhelming. Our skilled technicians simplify this process with expert support in IT, Networking, Point of Sale, Cloud Services, and more. Explore our full range of services below to discover how we can assist you.</p>
                        <div className="w3banner-buttons">
                            <a href="/services" className="btn btn-style btn-primary mt-sm-3 mb-4">View All Services</a>
                        </div>
                    </div>

                    <div id="brands" className="row">
                        <div className="col-lg-6 pr-lg-3 mb-3">
                            <div className="title-content text-left">
                                <h6 className="title-subhny mb-2">Brands We Use</h6>
                                <h1 className="title-w3l">Industry Respected Brands.</h1>
                            </div>
                        </div>

                    </div>

                    <div className="row pt-md-0 pt-5">
                        <div className="col-lg-4 col-md-6 mt-md-0 mt-sm-4 px-lg-0">
                            <a href="https://synology.com" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/synology-logo.jpg" alt="" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-md-6 mt-md-0 mt-sm-4 px-lg-0">
                            <a href="https://www.ui.com" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/ubiquiti-logo.png" alt="" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-md-6 mt-md-0 mt-sm-4 px-lg-0">
                            <a href="https://www.microsoft.com" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/microsoft-logo.png" alt="" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-md-6 mt-md-0 mt-sm-4 px-lg-0">
                            <a href="https://www.atera.com" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img width="555px" height="383px" src="assets/images/atera.png" alt="" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-md-6 mt-md-0 mt-sm-4 px-lg-0">
                            <a href="https://squareup.com" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/square-logo.jpg" alt="" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-md-6 mt-md-0 mt-sm-4 px-lg-0">
                            <a href="https://samsung.com" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/samsung-logo.png" alt="" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            {/* 
            <section className="w3l-about-ab w3l-content-8" id="about">
                <div className="midd-w3 py-5">
                    <div className="container py-lg-5 py-md-4 py-2">
                        <div className="row">
                            <div className="col-lg-6 left-wthree-img pr-lg-5">
                                <div className="imgw3l-ab w3l-news">
                                    <div className="grids5-info">
                                        <a href="#" className="d-block zoom"><img src="assets/images/5.jpg" alt="" className="img-fluid news-image" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 pl-lg-5 mt-lg-0 mt-5">
                                <div className="title-content text-left">
                                    <h6 className="title-subhny mb-2">Why Choose Us</h6>
                                    <h3 className="title-w3l mb-4"> The clever industrial business you can feel.</h3>
                                </div>
                                <p className="pr-lg-5">Lorem ipsum viverra feugiat. Pellen tesque libero ut justo,
                                    ultrices in ligula. Semper at tempufddfel. Learn more about our work!
                                    Lorem ipsum viverra feugiat.
                                </p>
                                <ul className="icon-list-items mt-5">
                                    <li className="icon-list-item">
                                        <i aria-hidden="true" className="fas fa-check"></i>
                                        Power & Energy
                                    </li>
                                    <li className="icon-list-item ml-lg-4">
                                        <i aria-hidden="true" className="fas fa-check"></i>
                                        Mechanical Works
                                    </li>
                                    <li className="icon-list-item">
                                        <i aria-hidden="true" className="fas fa-check"></i>
                                        Petroleum Refinery
                                    </li>
                                    <li className="icon-list-item ml-lg-4">
                                        <i aria-hidden="true" className="fas fa-check"></i>
                                        Oil and Gas
                                    </li>
                                </ul>
                                <a href="services.html" className="btn btn-style btn-primary mt-lg-5 mt-4">More Services

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            */}

            <section class="w3l-featureshny py-5" id="features">
                <div class="container py-md-5">
                    <div class="row">
                        <div class="col-lg-5 pr-lg-5 mb-lg-0 mb-5">
                            <div class="w3l-header-sec text-left mb-md-5 mb-4">
                                <h6 class="title-subhny mb-2" style={{color:"white"}}>About Us</h6>
                                <h1 style={{color:"white"}} class="">View Our Passionate and Experienced Team of Technology Experts</h1>
                                <a href="/team" class="btn btn-primary btn-white btn-style mt-lg-5 mt-4">Our Team</a>
                            </div>
                        </div>
                        {/*
                        <div class="col-lg-7 statsw3-left pl-lg-5">
                            <div class="row w3l-stats-section stats-con">
                                <div class="col-lg-4 col-6 stats_info counter_grid">
                                    <i class="far fa-smile"></i>
                                    <p class="counter">1200 </p>
                                    <h3>Happy clients</h3>
                                </div>
                                <div class="col-lg-4 col-6 stats_info counter_grid mt-md-0 mt-0">
                                    <i class="fas fa-trophy"></i>
                                    <p class="counter">2500</p>
                                    <h3>Projects done</h3>
                                </div>
                                <div class="col-lg-4 col-6 stats_info counter_grid2 mt-md-0 mt-4">
                                    <i class="fas fa-project-diagram"></i>
                                    <p class="counter">1545 </p>
                                    <h3>Awards won</h3>
                                </div>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </section>

            {/*}
            <section class="w3l-products w3l-faq-block py-5" id="projects">
                <div class="container py-lg-5 py-md-4 py-2">
                    <div class="row ">
                        <div class="col-lg-6 mx-auto pr-lg-5 w3l-news">

                            <div class="grids5-info">
                                <a href="#" class="d-block zoom"><img src="assets/images/ab1.jpg" alt="" class="img-fluid news-image" /></a>
                            </div>
                        </div>
                        <div class="col-lg-6 mt-lg-0 mt-sm-5 mt-5">
                            <h6 class="title-subhny mb-2"><span>Ask by Client</span></h6>
                            <h3 class="title-w3l mb-lg-5 mb-4">Frequently Asked Questions</h3>
                            <div class="accordion">
                                <div class="accordion-item">
                                    <button id="accordion-button-1" aria-expanded="true"><span class="accordion-title">Question Number
                                        one?</span><span class="icon" aria-hidden="true"></span></button>
                                    <div class="accordion-content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis
                                            ut. Ut tortor.</p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <button id="accordion-button-2" aria-expanded="false"><span class="accordion-title">Question Number
                                        Two?</span><span class="icon" aria-hidden="true"></span></button>
                                    <div class="accordion-content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis
                                            ut. Ut pretium.</p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <button id="accordion-button-3" aria-expanded="false"><span class="accordion-title">Question Number
                                        Three?</span><span class="icon" aria-hidden="true"></span></button>
                                    <div class="accordion-content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis
                                            ut. Ut tortor.</p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <button id="accordion-button-4" aria-expanded="false"><span class="accordion-title">Question Number
                                        Four?</span><span class="icon" aria-hidden="true"></span></button>
                                    <div class="accordion-content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis
                                            ut. Ut potenti.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            /*}
            {/*
            <div class="w3l-news text-11" id="news">
                <section id="grids5-block" class="py-5">
                    <div class="container py-lg-5 py-md-4 py-2">
                        <h6 class="title-subhny mb-2 text-center mx-auto d-block">Insight and Trends</h6>
                        <h3 class="title-w3l mb-lg-5 mb-4 text-center">Recent Articles</h3>
                        <div class="row mt-lg-5 mt-4 text11-content">
                            <div class="col-lg-4 col-md-6 mt-lg-0 mt-5">
                                <div class="grids5-info">
                                    <a href="#" class="d-block zoom"><img src="assets/images/banner3.jpg" alt=""
                                        class="img-fluid news-image" /></a>
                                    <div class="blog-info card-body blog-details px-lg-0">
                                        <h6 class="date">May 15, 2021.</h6>
                                        <a href="#category" class="post">Industrial,Factory</a>
                                        <a href="blog-single.html" class="blog-desc">Lorem ipsum viverra feugiat. Pellen tesque libero ut justo,
                                            ultrices in ligula. Semper at tempufddfel.</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-lg-0 mt-5">
                                <div class="grids5-info">
                                    <a href="#" class="d-block zoom"><img src="assets/images/banner4.jpg" alt=""
                                        class="img-fluid news-image" /></a>
                                    <div class="blog-info card-body blog-details px-lg-0">
                                        <h6 class="date">May 15, 2021.</h6>
                                        <a href="#category" class="post">Production,Construction</a>
                                        <a href="blog-single.html" class="blog-desc">Lorem ipsum viverra feugiat. Pellen tesque libero ut justo,
                                            ultrices in ligula. Semper at tempufddfel.</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-lg-0 mt-5">
                                <div class="grids5-info">
                                    <a href="#" class="d-block zoom"><img src="assets/images/banner5.jpg" alt=""
                                        class="img-fluid news-image" /></a>
                                    <div class="blog-info card-body blog-details px-lg-0">
                                        <h6 class="date">May 15, 2021.</h6>
                                        <a href="#category" class="post">Factory,Fitting</a>
                                        <a href="blog-single.html" class="blog-desc">Lorem ipsum viverra feugiat. Pellen tesque libero ut justo,
                                            ultrices in ligula. Semper at tempufddfel.</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="blog-btn mx-auto text-center mt-4">
                            <a href="about.html" class="btn btn-primary btn-style mt-lg-5 mt-4"> More Articles</a>
                        </div>
                    </div>
                </section>
            </div>
            */}

            {/*
            <section class="w3l-clients py-5" id="clients">
                <div class="cusrtomer-layout py-5">
                    <div class="container">
                        <div class="heading text-center mx-auto">
                            <h6 class="title-subhny mb-2">Testimonials</h6>
                            <h3 class="title-w3l mb-5">Client’s Testimonials

                            </h3>
                        </div>

                        <div class="testimonial-width pb-5">
                            <div id="owl-demo1" class="owl-two owl-carousel owl-theme">
                                <div class="item">
                                    <div class="testimonial-content">
                                        <div class="testimonial">
                                            <blockquote>
                                                <i class="fas fa-quote-left"></i>
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit beatae
                                                    laudantium
                                                    voluptate rem ullam dolore nisi voluptatibus esse quasi. Lorem ipsum dolor sit amet elit. Non
                                                    quae, fugiat nihil ad. Lorem ipsum dolor sit amet.</p>
                                            </blockquote>
                                            <div class="testi-des">
                                                <div class="test-img"><img src="assets/images/team1.jpg" class="img-fluid" alt="client-img" />
                                                </div>
                                                <div class="peopl align-self">
                                                    <h3>John wilson</h3>
                                                    <p class="indentity">Example City</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="testimonial-content">
                                        <div class="testimonial">
                                            <blockquote>
                                                <i class="fas fa-quote-left"></i>
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit beatae
                                                    laudantium
                                                    voluptate rem ullam dolore nisi voluptatibus esse quasi. Lorem ipsum dolor sit amet elit. Non
                                                    quae, fugiat nihil ad. Lorem ipsum dolor sit amet.</p>
                                            </blockquote>
                                            <div class="testi-des">
                                                <div class="test-img"><img src="assets/images/team2.jpg" class="img-fluid" alt="client-img" />
                                                </div>
                                                <div class="peopl align-self">
                                                    <h3>Julia sakura</h3>
                                                    <p class="indentity">Example City</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="testimonial-content">
                                        <div class="testimonial">
                                            <blockquote>
                                                <i class="fas fa-quote-left"></i>
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit beatae
                                                    laudantium
                                                    voluptate rem ullam dolore nisi voluptatibus esse quasi. Lorem ipsum dolor sit amet elit. Non
                                                    quae, fugiat nihil ad. Lorem ipsum dolor sit amet.</p>
                                            </blockquote>
                                            <div class="testi-des">
                                                <div class="test-img"><img src="assets/images/team3.jpg" class="img-fluid" alt="client-img" />
                                                </div>
                                                <div class="peopl align-self">
                                                    <h3>Roy Linderson</h3>
                                                    <p class="indentity">Example City</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
             */}

            {/*
            <section class="w3l-subscribe-content py-5">
                <div class="container py-md-4 py-3 text-center">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="header-section text-left">
                                <h6 class="title-subhny mb-2"><span>Join Us</span></h6>
                                <h3 class="title-w3l">Stay
                                    Updated!</h3>
                                <p class="mt-2">Get Updates By Subscribe Our Weekly Newsletter</p>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="subscribe">
                                <form action="#" method="post" class="subscribe-wthree pt-2 mt-5">
                                    <div class="d-sm-flex flex-wrap subscribe-wthree-field">
                                        <input class="form-control" type="email" placeholder="Enter your email..." name="email" required="" />
                                        <button class="btn btn-style btn-primary" type="submit">Subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
             */}

            <Footer/>
        </body>
    );
}

export default Home;