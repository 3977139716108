import React, { useState, useEffect } from 'react';

function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

function Footer(props) {
    return (
        <footer class="footer-17">
        <div style={{textAlign: "left", fontFamily: "'Work Sans', sans-serif;"}} class="footer17_sur py-5">
            <div class="container py-lg-5 py-md-4">
                <div class="row footer17-top">
                    <div class="col-lg-4 footer17-top-left2_sur pr-lg-5">
                        <h6>About Us</h6>
                        <p>Still have questions or concerns?</p>
                        <a href="/contact" class="btn btn-style btn-primary mt-lg-5 mt-4">Contact Us</a>
                    </div>
                    <div class="col-lg-4 footer17-top-left1_sur mt-lg-0 my-5">
                        <h6>Quick Links</h6>
                        <div class="footer-listw3-grids">
                            <ul class="footer-listw3">
                                <li><a href="/">Home</a></li>
                                <li><a href="/team">Team</a></li>
                                <li><a href="/services">Services</a></li>
                                <li><a href="/contact">Contact</a></li>

                            </ul>
                            {/*
                            <ul class="footer-listw3">
                                <li><a href="#privacy">Privacy Policy</a></li>
                                <li><a href="#term">Terms of use</a></li>
                                <li><a href="#sales">Sales and Refunds</a></li>
                                <li><a href="#faq">FAQ</a></li>
                            </ul>
                            */}
                        </div>
                    </div>
                    <div class="col-lg-4 footer17-top-left4_sur footerw3-instagram pl-lg-5 ">
                        <h6>Social</h6>

                        {/*
                        <div class="row">
                            <div class="col-4 img-gd-f">
                                <a href="#"> <img src="assets/images/sonos.png" alt="" class="radius-image img-fluid" /></a>
                            </div>
                            <div class="col-4 img-gd-f">
                                <a href="#"> <img src="assets/images/2.jpg" alt="" class="radius-image img-fluid" /></a>
                            </div>
                            <div class="col-4 img-gd-f">
                                <a href="#"> <img src="assets/images/3.jpg" alt="" class="radius-image img-fluid" /></a>
                            </div>
                            <div class="col-4 img-gd-f mt-4">
                                <a href="#"> <img src="assets/images/4.jpg" alt="" class="radius-image img-fluid" /></a>
                            </div>
                            <div class="col-4 img-gd-f mt-4">
                                <a href="#"> <img src="assets/images/5.jpg" alt="" class="radius-image img-fluid" /></a>
                            </div>
                            <div class="col-4 img-gd-f mt-4">
                                <a href="#"> <img src="assets/images/1.jpg" alt="" class="radius-image img-fluid" /></a>
                            </div>
                        </div>
                        */}
                        
                        <ul class="footers-17_social">
                            <li><a target="_blank" href="https://facebook.com/solaceintegrations" class="facebook"><span class="fab fa-facebook-f"></span></a></li>
                            {/*<li><a href="#url" class="linkedin"><span class="fab fa-linkedin-in"></span></a></li>*/}
                            <li><a target="_blank" href="https://www.instagram.com/solaceintegrations/" class="instagram"><span class="fab fa-instagram"></span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="copyright text-center">
                <div class="container">
                    <div class="container">
                        <p class="copy-footer-29">© 2024 Solace Home Integrations LLC. All rights reserved.</p>
                    </div>
                </div>
            </div>

            <button onClick={() => topFunction()} id="movetop" title="Go to top">
                <i class="fas fa-long-arrow-alt-up"></i>
            </button>


        </div>
    </footer>);
}

export default Footer;