import Header from '../Components/Header';
import React, { useState, useEffect } from 'react';



function Error() {
    return (
        <div>
            <Header isFixed />
            <div style={{minHeight: "100px"}} ></div>
            <div class="w3l-error-grid py-5 text-center">
    <div class="container py-lg-5 py-md-5">
        <div class="error-block">
            <h1>4<span class="fas fa-smile-wink"></span>4</h1>
            <h2>Oops, Page not found!</h2>
            <a href="/" class="btn back-button mt-md-5 mt-4"> <span class="fa fa-arrow-left mr-2"></span> Back to Home</a>
        </div>
    </div>
</div>
        </div>
    );
}

export default Error;