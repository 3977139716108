import Header from '../Components/Header';
import Footer from '../Components/Footer';

export default function () {
  return (
    <div style={{backgroundColor: "white"}}>
      <Header isFixed />
      <div style={{minHeight: "100px"}} ></div>
    
      <section class="team">
        <div className="">
          <div className="container pt-md-5 pt-2 text-center">
            <h3 style={{ color: "rgba(0,42,123,.71)" }} class="services-title text-center mb-lg-5 mb-3 px-lg-5"><span style={{ color: "#55af4c" }} class="sub-title">WHO WE ARE.</span>OUR TEAM</h3>


          </div>
          <div className="container">
            <div class="row mt-5"><div class="col-md-4 team-img-l">
              <img width="100%" alt="Christian Musial" class="team-img-actual" src="assets/images/christian.jpg" />
            </div>
              <div class="col-md-8 p-4 bg-main text-white team-desc-l" style={{textAlign:"left",backgroundColor:"rgb(32, 61, 107)"}}>
                <h3 style={{color:"white"}}>Christian Musial</h3>
                <h6 style={{color:"white",fontWeight:"100"}}>Owner and Lead Technician</h6>
                <p class="mt-3" style={{color:"white"}}>Christian Musial graduated with a B.S. in Computer Science from Univeristy of Maryland in 2019.
                  Christian has over 5 years of IT and related experience, spanning a broad range of disciplines, such as software engineering,
                  web development, systems administration, audio-video, and low voltage electrical work. He has worked across multiple industries
                  such as healthcare, government, financial, and for residential clients.</p>
              </div>
            </div>
          </div>

        </div>
      </section>
      <Footer/>


    </div>
  );
}